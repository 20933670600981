import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import BannerSlider from '../../components/home/BannerSlider';
import PopularCategories from '../../components/shared/PopularCategories';
import DiscountBannerOmg from '../../components/shared/DiscountBannerOmg';
import NewProducts from '../../components/products/NewProducts';
import CompaignSection from '../../components/shared/CompaignSection';
import TodayHotDeal from '../../components/products/TodayHotDeal';
import CustomerTestimonial from '../../components/shared/CustomerTestimonial';
import PopularProducts from '../../components/products/PopularProducts';
import ContactBanner from '../../components/shared/ContactBanner';
import { Modal } from 'bootstrap'; // Import Bootstrap's modal class
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Ensure Bootstrap JS is also included

const Home = () => {
    const modalRef = useRef(null);
    const navigate = useNavigate();
    const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
    useEffect(() => {
        const modalElement = document.getElementById('surveyModal');
        if (modalElement) {
            const myModal = new Modal(modalElement);
            setTimeout(() => {
                myModal.show();
            }, 500);
        }
    }, []);

    const handleCloseModal = () => {
        const modalElement = modalRef.current;
        if (modalElement) {
            const myModal = Modal.getInstance(modalElement); // Get instance of the modal
            myModal.hide(); // Close the modal
        }
    };

    return (
        <>

            <Helmet>
                <title>{siteTitle} - Home</title>
                <meta name="description" content={siteTitle + " - Home"} />
                <meta name="keywords" content="Home"></meta>
            </Helmet>

            <div className="bg-light">
                <BannerSlider />
                <PopularCategories />
                <DiscountBannerOmg />
                <NewProducts effect="icon-inline" />
                <CompaignSection />
                <TodayHotDeal />
                <CustomerTestimonial />
                <PopularProducts hoverEffect="icon-inline" />
                <ContactBanner />
            </div>


            {/* Bootstrap Modal */}
            <div className="modal fade" id="surveyModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">"We'd Love Your Feedback! 💬"</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <p>Your opinion matters to us! Help us improve your experience by taking a quick survey.
                                It only takes a minute, and as a thank you, you’ll get exclusive offers and discounts!</p>
                        </div>
                        <div className="modal-footer">
                            <a href="https://share.hsforms.com/1L_Vzmq7wTfeXgwFsNK01EQsove1" target="_blank" rel="noopener noreferrer">
                                <button type="button" className="btn btn-primary">
                                    Start Survey
                                </button>
                            </a>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default Home;


